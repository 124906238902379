@font-face {
  font-family: 'Barlow';
  src: url('./Assets/fonts/Barlow/Barlow-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url('./Assets/fonts/Barlow/Barlow-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url('./Assets/fonts/Barlow/Barlow-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url('./Assets/fonts/Barlow/Barlow-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url('./Assets/fonts/Barlow/Barlow-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'PT Mono';
  src: url('./Assets/fonts/PT_Mono/PTMono-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'PT Mono';
  src: url('./Assets/fonts/PT_Mono/PTMono-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
